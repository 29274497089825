import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IResaleConfig } from "../../models"

interface IResaleConfigState {
    resaleConfig?: IResaleConfig
    imageFile?: File
    faviconFile?: File
    loginPageImg?: File
}

const initialState: IResaleConfigState = {
    resaleConfig: {
        companyId: "",
        name: "",
        img: "",
        favicon: "",
        loginPageImg: "",
        domainFrontEnd: "",
        domainBackEnd: undefined,
        contact: {
            email: "",
            phone: "",
            beginHour: '08:00',
            endHour: '18:00'
        },
        emailProviderSettings: {
            user: "",
            password: "",
            host: "",
            port: ""
        },
        theme: {
            colors: {
                primary: {
                    main: "",
                    contrastColor: ""
                },
                secondary: {
                    main: "",
                    contrastColor: ""
                },
                background: {
                    paper: {
                        main: ""
                    },
                    body: {
                        main: ""
                    }
                }
            },
            fontFamily: undefined
        },
        id: "",
        createdAt: new Date(),
        updatedAt: new Date()
    }
}

const resaleConfigSlice = createSlice({
    initialState,
    name: 'resaleConfig',
    reducers: {
        setResaleConfigInitialState(state) {
            state.resaleConfig = initialState.resaleConfig
        },
        resetResaleConfigState(state) {
            if (!state.resaleConfig) return
            state.resaleConfig.contact = initialState.resaleConfig!.contact
            state.resaleConfig.createdAt = initialState.resaleConfig!.createdAt
            state.resaleConfig.domainFrontEnd = initialState.resaleConfig!.domainFrontEnd
            state.resaleConfig.emailProviderSettings = initialState.resaleConfig!.emailProviderSettings
            state.resaleConfig.favicon = initialState.resaleConfig!.favicon
            state.resaleConfig.img = initialState.resaleConfig!.img
            state.resaleConfig.loginPageImg = initialState.resaleConfig!.loginPageImg
            state.resaleConfig.name = initialState.resaleConfig!.name
            state.resaleConfig.theme = initialState.resaleConfig!.theme
            state.resaleConfig.updatedAt = initialState.resaleConfig!.updatedAt
            state.faviconFile = initialState.faviconFile
            state.imageFile = initialState.imageFile
            state.loginPageImg = initialState.loginPageImg
        },
        setResaleConfigState(state, action: PayloadAction<{
            resaleConfig: IResaleConfig
        }>) {
            state.resaleConfig = action.payload.resaleConfig
        },
        setResaleConfigNameState(state, action: PayloadAction<{
            name: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.name = action.payload.name
            }
        },
        setResaleConfigImgState(state, action: PayloadAction<{
            img: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.img = action.payload.img
            }
        },
        setResaleConfigImageFileState(state, action: PayloadAction<{
            imageFile?: File
        }>) {
            state.imageFile = action.payload.imageFile
        },
        setResaleConfigFaviconState(state, action: PayloadAction<{
            favicon: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.favicon = action.payload.favicon
            }
        },
        setResaleConfigFaviconFileState(state, action: PayloadAction<{
            faviconFile?: File
        }>) {
            state.faviconFile = action.payload.faviconFile
        },
        setResaleConfigLoginPageImgState(state, action: PayloadAction<{
            loginPageImg: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.loginPageImg = action.payload.loginPageImg
            }
        },
        setResaleConfigLoginPageImgFileState(state, action: PayloadAction<{
            loginPageImg?: File
        }>) {
            state.loginPageImg = action.payload.loginPageImg
        },
        setResaleConfigDomainFrontEndState(state, action: PayloadAction<{
            domainFrontEnd: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.domainFrontEnd = action.payload.domainFrontEnd
            }
        },
        setResaleConfigDomainBackEndState(state, action: PayloadAction<{ domainBackEnd?: string }>) {
            if (state.resaleConfig) {
                state.resaleConfig.domainBackEnd = action.payload.domainBackEnd;
            }
        },        
        setResaleConfigContactEmailState(state, action: PayloadAction<{
            email: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.contact.email = action.payload.email
            }
        },
        setResaleConfigContactPhoneState(state, action: PayloadAction<{
            phone: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.contact.phone = action.payload.phone
            }
        },
        setResaleConfigContactBeginHourState(state, action: PayloadAction<{
            beginHour: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.contact.beginHour = action.payload.beginHour
            }
        },
        setResaleConfigContactEndHourState(state, action: PayloadAction<{
            endHour: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.contact.endHour = action.payload.endHour
            }
        },
        setResaleConfigEmailProviderSettingUserState(state, action: PayloadAction<{
            user: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.emailProviderSettings.user = action.payload.user
            }
        },
        setResaleConfigEmailProviderSettingPasswordState(state, action: PayloadAction<{
            password: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.emailProviderSettings.password = action.payload.password
            }
        },
        setResaleConfigEmailProviderSettingHostState(state, action: PayloadAction<{
            host: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.emailProviderSettings.host = action.payload.host
            }
        },
        setResaleConfigEmailProviderSettingPortState(state, action: PayloadAction<{
            port: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.emailProviderSettings.port = action.payload.port
            }
        },
        setResaleConfigThemeColorsPrimaryMainState(state, action: PayloadAction<{
            mainColor: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.theme.colors.primary.main = action.payload.mainColor
            }
        },
        setResaleConfigThemeColorsPrimaryContrastColorState(state, action: PayloadAction<{
            contrastColor: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.theme.colors.primary.contrastColor = action.payload.contrastColor
            }
        },
        setResaleConfigThemeColorsSecondaryMainState(state, action: PayloadAction<{
            mainColor: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.theme.colors.secondary.main = action.payload.mainColor
            }
        },
        setResaleConfigThemeColorsSecondaryContrastColorState(state, action: PayloadAction<{
            contrastColor: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.theme.colors.secondary.contrastColor = action.payload.contrastColor
            }
        },
        setResaleConfigThemeColorsBackgroundPaperMainState(state, action: PayloadAction<{
            mainColor: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.theme.colors.background.paper.main = action.payload.mainColor
            }
        },
        setResaleConfigThemeColorsBackgroundBodyMainState(state, action: PayloadAction<{
            mainColor: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.theme.colors.background.body.main = action.payload.mainColor
            }
        },
        setResaleConfigThemeFontFamilyState(state, action: PayloadAction<{
            fontFamily: string
        }>) {
            if (state.resaleConfig) {
                state.resaleConfig.theme.fontFamily = action.payload.fontFamily
            }
        }
    }
})

export const {
    resetResaleConfigState,
    setResaleConfigState,
    setResaleConfigContactBeginHourState,
    setResaleConfigContactEmailState,
    setResaleConfigContactEndHourState,
    setResaleConfigContactPhoneState,
    setResaleConfigDomainFrontEndState,
    setResaleConfigDomainBackEndState,
    setResaleConfigEmailProviderSettingHostState,
    setResaleConfigEmailProviderSettingPasswordState,
    setResaleConfigEmailProviderSettingPortState,
    setResaleConfigEmailProviderSettingUserState,
    setResaleConfigFaviconState,
    setResaleConfigFaviconFileState,
    setResaleConfigImgState,
    setResaleConfigImageFileState,
    setResaleConfigNameState,
    setResaleConfigThemeColorsBackgroundBodyMainState,
    setResaleConfigThemeColorsBackgroundPaperMainState,
    setResaleConfigThemeColorsPrimaryContrastColorState,
    setResaleConfigThemeColorsPrimaryMainState,
    setResaleConfigThemeColorsSecondaryContrastColorState,
    setResaleConfigThemeColorsSecondaryMainState,
    setResaleConfigThemeFontFamilyState,
    setResaleConfigLoginPageImgFileState,
    setResaleConfigLoginPageImgState,
    setResaleConfigInitialState
} = resaleConfigSlice.actions

export const resaleConfigReducer = resaleConfigSlice.reducer