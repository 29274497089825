import { BackofficeTabs, setActiveBackofficeTab } from "../../application/states/backoffice"
import { useAppDispatch, useAppSelector } from "../../application/states/hooks"
import { useCallback } from 'react'
import { useLoadingService } from "../loading"
import { useBackofficeCompanyApi, useBackofficeConsumptionApi, useBackofficeLocationApi, useBackofficeNotificationApi, useBackofficePJApi, useBackofficeRevenueReportApi, useBackofficeSeparatedCreditsApi, useBackofficeSubscriptionApi, useUserApi } from "../../infra"
import { addRazaoSocialFilterState, removeRazaoSocialFilterState, resetCompanyFormErrors, resetSelectedCompanyState, setCompaniesSearchTermState, setCompaniesState, setCompanyFormFieldErrors, setCompanyFormOpenState, setCompanyImageFileState, setResetSelectedCompanyResaleConfigState, setSelectedCompanyResaleConfigContactBeginHourState, setSelectedCompanyResaleConfigContactEmailState, setSelectedCompanyResaleConfigContactEndHourState, setSelectedCompanyResaleConfigContactPhoneState, setSelectedCompanyResaleConfigDomainFrontEndState, setSelectedCompanyResaleConfigDomainBackEndState, setSelectedCompanyResaleConfigEmailProviderSettingHostState, setSelectedCompanyResaleConfigEmailProviderSettingPasswordState, setSelectedCompanyResaleConfigEmailProviderSettingPortState, setSelectedCompanyResaleConfigEmailProviderSettingUserState, setSelectedCompanyResaleConfigFaviconFileState, setSelectedCompanyResaleConfigFaviconState, setSelectedCompanyResaleConfigImageFileState, setSelectedCompanyResaleConfigImgState, setSelectedCompanyResaleConfigLoginPageImgFileState, setSelectedCompanyResaleConfigLoginPageImgState, setSelectedCompanyResaleConfigNameState, setSelectedCompanyResaleConfigState, setSelectedCompanyResaleConfigThemeColorsBackgroundBodyMainState, setSelectedCompanyResaleConfigThemeColorsBackgroundPaperMainState, setSelectedCompanyResaleConfigThemeColorsPrimaryContrastColorState, setSelectedCompanyResaleConfigThemeColorsPrimaryMainState, setSelectedCompanyResaleConfigThemeColorsSecondaryContrastColorState, setSelectedCompanyResaleConfigThemeColorsSecondaryMainState, setSelectedCompanyResaleConfigThemeFontFamilyState, setSelectedCompanyState, setSubscriptionDashboardOpenState, validateCompanyForm } from "../../application/states/backoffice/company"
import { IBackofficeCompany, IBackofficeNotification, IBackofficeSeparatedCredits, IBackofficeSubscription, IResaleConfig } from "../../application/models"
import { executeAlert } from "../../application/states/toast-alert"
import { setConsumptionReportState } from "../../application/states/backoffice/consumption"
import { resetNotificationFormErrors, resetSelectedNotificationState, setNotificationFormFieldErrors, setNotificationFormOpenState, setNotificationImageFileState, setNotificationsSearchTermState, setNotificationsState, setSelectedNotificationState, validateNotificationForm } from "../../application/states/backoffice/notification"
import { setRevenueReportDateState, setRevenueReportResultState, setRevenueReportState } from "../../application/states/backoffice/revenue-report"
import { xlsxGenerator } from "../../utils/convert-to-xlsx/xslxGenerator"
import { useSubscriptionApi } from "../../infra/subscription"
import { numericCnpj, onlyNumbers } from "../../utils"
import { useConsumptionApi } from "../../infra/consumption-api"

export const useBackofficeService = () => {
    // Dispatch
    const dispatch = useAppDispatch()
    // Infra - APIs
    const { getCompaniesList, createCompanyApi, updateCompanyApi } = useBackofficeCompanyApi()
    const { findByCnpj } = useBackofficePJApi()
    const { updateSubscriptionApi } = useBackofficeSubscriptionApi()
    const { findLocationByCepApi } = useBackofficeLocationApi()
    const { createSeparatedCredits } = useBackofficeSeparatedCreditsApi()
    const { getConsumptionReportApi } = useBackofficeConsumptionApi()
    const { createNotificationApi, findCustomNotificationsApi } = useBackofficeNotificationApi()
    const { findCompanySubscription: findSubscription, getSubscriptionUsage } = useSubscriptionApi()
    const { getRevenueReportApi, getResaleRevenueReportApi } = useBackofficeRevenueReportApi()
    const { findUserByCompanyIdApi } = useUserApi()
    const { getUserConsumptionApi, createConsumptionLockApi } = useConsumptionApi()
    // Services
    const { startLoading, stopLoading } = useLoadingService()
    // States
    const { selectedSeller } = useAppSelector((state) => state.user)
    const selectedCompany = useAppSelector((state) => state.backofficeCompany.selectedCompany)
    const selectedCompanyResaleConfig = useAppSelector((state) => state.backofficeCompany.selectedCompanyResaleConfig)
    const companyState = useAppSelector((state) => state.company)
    const userState = useAppSelector((state) => state.user)
    const notificationState = useAppSelector((state) => state.backofficeNotification)
    const revenueReportState = useAppSelector((state) => state.backofficeRevenueReport)
    const { subscription } = useAppSelector((state) => state.subscription)
    const { resaleConfig } = useAppSelector((state) => state.resaleConfig)
    const { companyList } = useAppSelector((state) => state.backofficeCompany)

    const openSubscriptionDashboard = useCallback(() => {
        dispatch(setSubscriptionDashboardOpenState({
            open: true
        }))
    }, [dispatch])

    const closeSubscriptionDashboard = useCallback(() => {
        dispatch(setSubscriptionDashboardOpenState({
            open: false
        }))
    }, [dispatch])

    const openCompanyForm = useCallback(() => {
        dispatch(setCompanyFormOpenState({
            open: true
        }))
    }, [dispatch])

    const closeCompanyForm = useCallback(() => {
        dispatch(resetSelectedCompanyState())
        dispatch(setCompanyFormOpenState({
            open: false
        }))
    }, [dispatch])

    const setCompaniesList = useCallback(() => {
        if (companyState) {
            if (companyState.company) {
                if (companyState.company.id === '') {
                    return
                }

                startLoading()
                getCompaniesList({
                    userId: userState.id
                }).then((companiesList) => {
                    dispatch(setCompaniesState({
                        companyList: companiesList
                    }))
                    stopLoading()
                }).catch((error) => {
                    dispatch(executeAlert({
                        message: error.message,
                        type: 'error'
                    }))
                    stopLoading()
                })
            }
        }

    }, [companyState, startLoading, getCompaniesList, userState.id, dispatch, stopLoading])

    const setSearchTerm = useCallback((data: {
        searchTerm: string
    }) => {
        dispatch(setCompaniesSearchTermState({
            searchTerm: data.searchTerm
        }))
    }, [dispatch])

    const addRazaoSocialFilter = useCallback((data: {
        razaoSocial: string,
        companyId: string
    }) => {
        dispatch(addRazaoSocialFilterState({
            razaoSocial: data.razaoSocial,
            companyId: data.companyId
        }))
    }, [dispatch])

    const removeRazaoSocialFilter = useCallback((data: {
        razaoSocial: string,
        companyId: string
    }) => {
        dispatch(removeRazaoSocialFilterState({
            razaoSocial: data.razaoSocial,
            companyId: data.companyId
        }))
    }, [dispatch])

    const selectCompany = useCallback((data?: {
        company: IBackofficeCompany
        subscription: IBackofficeSubscription
        separatedCredits: IBackofficeSeparatedCredits[]
        resaleConfig?: IResaleConfig
    }) => {
        if (data) {
            dispatch(setSelectedCompanyState({
                company: data.company,
                subscription: data.subscription,
                separatedCredits: data.separatedCredits
            }))

            if (data.company.resale === true) {
                if (data.resaleConfig) {
                    dispatch(setSelectedCompanyResaleConfigState({
                        resaleConfig: data.resaleConfig!
                    }))
                }
            }
        }
    }, [dispatch])

    const createCompany = useCallback(() => {
        dispatch(resetCompanyFormErrors())
        if (!selectedCompany) {
            return
        }

        if (!companyState.company || companyState.company.id === '') {
            return
        }

        const errors = validateCompanyForm({
            company: selectedCompany.company,
            subscription: selectedCompany.subscription
        })
        if (errors.length > 0) {
            dispatch(setCompanyFormFieldErrors(errors))
            dispatch(executeAlert({
                message: errors[0].fieldError.message,
                type: 'error'
            }))
        } else {
            const lastRange = selectedCompany.subscription.ranges[selectedCompany.subscription.ranges.length - 1]
            if (lastRange) {
                if (lastRange.max) {
                    dispatch(executeAlert({
                        message: 'O último range deve ser infinito',
                        type: 'error'
                    }))
                    return
                }
            }

            createCompanyApi({
                companyData: {
                    cnpj: selectedCompany.company.cnpj,
                    razaoSocial: selectedCompany.company.razaoSocial,
                    nomeFantasia: selectedCompany.company.nomeFantasia,
                    email: selectedCompany.company.email,
                    address: selectedCompany.company.address,
                    phone: selectedCompany.company.phone,
                    img: selectedCompany.company.img,
                    master: false,
                    resale: companyState.company.master === true ? selectedCompany.company.resale : false,
                    resaleId: companyState.company.resale === true ? companyState.company.id : undefined
                },
                subscriptionData: {
                    name: selectedCompany.subscription.name,
                    createdBy: userState.id,
                    ranges: [
                        ...selectedCompany.subscription.ranges
                    ],
                    sellerId: selectedSeller?.id,
                    partnerCols: (companyState.company?.master === true || subscription?.partnerCols === true)
                        ? selectedCompany.subscription.partnerCols
                        : false
                },
                resaleConfig: selectedCompany.company.resale === true ? selectedCompanyResaleConfig.resaleConfig : undefined
            }).then((company) => {
                dispatch(executeAlert({
                    message: 'Empresa cadastrada com sucesso',
                    type: 'success'
                }))
                setCompaniesList()
                closeCompanyForm()
            }).catch((error) => {
                dispatch(executeAlert({
                    message: error.message,
                    type: 'error'
                }))
            })
        }
    }, [closeCompanyForm, companyState.company, createCompanyApi, dispatch, selectedCompany, selectedCompanyResaleConfig.resaleConfig, selectedSeller?.id, setCompaniesList, subscription?.partnerCols, userState.id])

    const updateCompany = useCallback(() => {
        if (!selectedCompany) {
            return
        }

        if (!companyState?.company || companyState.company.id === '') {
            return
        }

        dispatch(resetCompanyFormErrors())
        const errors = validateCompanyForm({
            company: selectedCompany.company,
            subscription: selectedCompany.subscription
        })

        if (errors.length > 0) {
            dispatch(setCompanyFormFieldErrors(errors))
            dispatch(executeAlert({
                message: errors[0].fieldError.message,
                type: 'error'
            }))
        } else {
            updateCompanyApi({
                company: {
                    id: selectedCompany.company.id,
                    cnpj: onlyNumbers(selectedCompany.company.cnpj),
                    razaoSocial: selectedCompany.company.razaoSocial.replace('  ', ' ').trim().toUpperCase(),
                    nomeFantasia: selectedCompany.company.nomeFantasia?.replace('  ', ' ').trim().toUpperCase(),
                    email: selectedCompany.company.email.replace(' ', '').toLowerCase(),
                    address: selectedCompany.company.address,
                    phone: onlyNumbers(selectedCompany.company.phone),
                    img: selectedCompany.company.img,
                    resale: selectedCompany.company.resale ?? false,
                    resaleId: companyState.company?.resale === true ? companyState.company.id : undefined,
                    active: selectedCompany.company.active
                },
                resaleConfig: selectedCompany.company.resale === true ? selectedCompanyResaleConfig.resaleConfig : undefined
            }).then(() => {
                if (selectedCompany.subscription) {
                    updateSubscriptionApi({
                        subscriptionId: selectedCompany.subscription.id,
                        sellerId: selectedSeller?.id,
                        name: selectedCompany.subscription.name,
                        ranges: selectedCompany.subscription.ranges,
                        partnerCols: selectedCompany.subscription.partnerCols
                    }).then(() => {
                        dispatch(executeAlert({
                            message: 'Empresa atualizada com sucesso',
                            type: 'success'
                        }))
                        setCompaniesList()
                        closeCompanyForm()
                    }).catch((error) => {
                        dispatch(executeAlert({
                            message: error.message,
                            type: 'error'
                        }))
                    })
                }
            }).catch((error) => {
                dispatch(executeAlert({
                    message: error.message,
                    type: 'error'
                }))
            })
        }
    }, [closeCompanyForm, companyState.company, dispatch, selectedCompany, selectedCompanyResaleConfig.resaleConfig, selectedSeller?.id, setCompaniesList, updateCompanyApi, updateSubscriptionApi])

    const feedCompanyForm = useCallback(() => {
        startLoading()
        findByCnpj({
            cnpj: selectedCompany?.company.cnpj ? onlyNumbers(selectedCompany.company.cnpj) : '',
            userId: userState.id
        }).then((res) => {
            selectCompany({
                company: {
                    ...selectedCompany!.company,
                    razaoSocial: res.pj.razaosocial?.replace('  ', ' ').trim().toUpperCase() ?? '',
                    nomeFantasia: res.pj.nomefantasia?.replace('  ', ' ').trim().toUpperCase() ?? '',
                    address: {
                        city: res.pj.cidade?.replace('  ', ' ').trim().toUpperCase() ?? '',
                        state: res.pj.uf?.replace(' ', '').trim().toUpperCase() ?? '',
                        street: `${res.pj.enderecotipo ?? ''} ${res.pj.enderecotitulo ?? ''} ${res.pj.enderecologradouro ?? ''}`.replace('  ', ' ').trim().toUpperCase(),
                        houseNumber: res.pj.endereconumero ?? '',
                        postalCode: res.pj.cep ? onlyNumbers(res.pj.cep) : '',
                        neighbourhood: res.pj.bairro?.replace('  ', ' ').trim().toUpperCase() ?? ''
                    }
                },
                subscription: {
                    ...selectedCompany!.subscription,
                },
                separatedCredits: [],
                resaleConfig: selectedCompanyResaleConfig.resaleConfig
            })
            stopLoading()
        }).catch((error) => {
            stopLoading()
            dispatch(executeAlert({
                message: `Nenhuma empresa encontrada com o CNPJ ${selectedCompany?.company.cnpj}`,
                type: 'error'
            }))
        })

    }, [dispatch, findByCnpj, selectCompany, selectedCompany, selectedCompanyResaleConfig.resaleConfig, startLoading, stopLoading, userState.id])

    const feedAddressFields = useCallback(() => {
        if (!selectedCompany) {
            return
        }

        if (selectedCompany.company.address.postalCode.length === 0) {
            return
        }

        startLoading()
        findLocationByCepApi({
            cep: selectedCompany.company.address.postalCode
        }).then((res) => {
            selectCompany({
                company: {
                    ...selectedCompany.company,
                    address: {
                        ...selectedCompany.company.address,
                        city: res.cidade ?? '',
                        state: res.uf ?? '',
                        street: res.logradouro ?? '',
                        houseNumber: '',
                        postalCode: res.cep ?? '',
                    }
                },
                subscription: {
                    ...selectedCompany.subscription,
                },
                separatedCredits: [],
                resaleConfig: selectedCompanyResaleConfig.resaleConfig
            })
            stopLoading()
        }).catch(() => {
            stopLoading()
        })
    }, [findLocationByCepApi, selectCompany, selectedCompany, selectedCompanyResaleConfig.resaleConfig, startLoading, stopLoading])

    const setCompanyFileImage = useCallback((newFile: File | null) => {
        if (newFile === null) {
            dispatch(setCompanyImageFileState({
                file: undefined,
            }))
            if (selectedCompany) {
                selectCompany({
                    company: {
                        ...selectedCompany.company,
                        img: undefined
                    },
                    subscription: {
                        ...selectedCompany.subscription,
                    },
                    separatedCredits: [],
                    resaleConfig: selectedCompanyResaleConfig.resaleConfig
                })
                return
            }
        }
        if (newFile!.type !== 'image/png' && newFile!.type !== 'image/jpg' && newFile!.type !== 'image/jpeg' && newFile!.type !== 'image/bmp') {
            dispatch(executeAlert({
                message: "Insira um arquivo .PNG, .JPG ou .JPEG.",
                type: "error"
            }))
            return
        }
        if (newFile!.size > (10 * 1024 * 1024)) {
            dispatch(executeAlert({
                message: "Arquivo muito grande. Insira um arquivo com no máximo 10MB.",
                type: "error"
            }))
            return
        }
        let fileReader = new FileReader()
        let isCancel = false;
        if (newFile) {
            fileReader.onload = (e: any) => {
                if (fileReader && fileReader.readyState === 1) {
                    fileReader.abort();
                    return;
                }
                const { result } = e.target;
                if (result && !isCancel) {
                    if (selectedCompany) {
                        selectCompany({
                            ...selectedCompany,
                            company: {
                                ...selectedCompany.company,
                                img: result
                            },
                            resaleConfig: selectedCompanyResaleConfig.resaleConfig
                        })
                    }
                }
                dispatch(setCompanyImageFileState({
                    file: newFile!,
                }))
            }
            fileReader.readAsDataURL(newFile);

        }

    }, [dispatch, selectCompany, selectedCompany, selectedCompanyResaleConfig.resaleConfig])

    const addSeparatedCredits = useCallback((data: {
        separatedCredits: | {
            value?: number,
            price?: number,
            expiresAt?: Date | null
        }
    }) => {
        if ((data.separatedCredits?.value ?? 9) <= 0) {
            dispatch(executeAlert({
                message: 'Quantidade de créditos separados deve ser maior que 0',
                type: 'error'
            }))
            return
        }
        if (!data.separatedCredits.expiresAt) {
            dispatch(executeAlert({
                message: 'Data de expiração é obrigatória',
                type: 'error'
            }))
            return
        }
        const expiresAt = data.separatedCredits.expiresAt
        expiresAt.setHours(23, 59, 59, 999)
        startLoading()
        createSeparatedCredits({
            separatedCreditsData: {
                companyId: selectedCompany?.company.id ?? '',
                value: data.separatedCredits.value!,
                price: data.separatedCredits.price!,
                expiresAt: expiresAt,
                sellerId: selectedCompany?.subscription.sellerId ?? ''
            }
        }).then((separatedCredits) => {
            selectCompany({
                ...selectedCompany!,
                separatedCredits: [
                    ...selectedCompany!.separatedCredits,
                    separatedCredits
                ],
                resaleConfig: selectedCompanyResaleConfig.resaleConfig
            })
            stopLoading()
            dispatch(executeAlert({
                message: 'Créditos separados adicionados com sucesso',
                type: 'success'
            }))

        }).catch((error) => {
            stopLoading()
            dispatch(executeAlert({
                message: error.message,
                type: 'error'
            }))
        })
    }, [createSeparatedCredits, dispatch, selectCompany, selectedCompany, selectedCompanyResaleConfig.resaleConfig, startLoading, stopLoading])

    const getConsumptionReport = useCallback((data: {
        startDate?: Date,
        endDate?: Date
    }) => {
        if (!data.startDate) {
            dispatch(executeAlert({
                message: "'Data inicial' é um campo obrigatório",
                type: 'error'
            }))
            return
        }

        if (!data.endDate) {
            dispatch(executeAlert({
                message: "'Data final' é um campo obrigatório",
                type: 'error'
            }))
            return
        }

        if (selectedCompany) {
            const endDate = data.endDate
            endDate.setHours(23, 59, 59, 999)
            startLoading()
            getConsumptionReportApi({
                companyId: selectedCompany.company.id,
                startDate: data.startDate,
                endDate: endDate
            }).then((res) => {
                if (res.consumptionsList.length === 0 || !res) {
                    stopLoading()
                    dispatch(executeAlert({
                        message: 'Nenhum consumo encontrado',
                        type: 'warning'
                    }))
                    return
                }
                findSubscription({
                    companyId: selectedCompany.company.id
                }).then((subscription) => {
                    getSubscriptionUsage({
                        companyId: selectedCompany.company.id
                    }).then((usage) => {
                        dispatch(setConsumptionReportState({
                            consumptionsList: res.consumptionsList,
                            subscriptionData: {
                                subscription: subscription,
                                usage: usage
                            }
                        }))
                        dispatch(executeAlert({
                            message: 'Consumo encontrado com sucesso',
                            type: 'success'
                        }))
                    })
                })
                stopLoading()
            })
        }
    }, [dispatch, findSubscription, getConsumptionReportApi, getSubscriptionUsage, selectedCompany, startLoading, stopLoading])

    const resetConsumptionReport = useCallback(() => {
        dispatch(setConsumptionReportState({
            consumptionsList: []
        }))
    }, [
        dispatch
    ])

    const setNotificationsList = useCallback(() => {
        startLoading()
        findCustomNotificationsApi({
            userId: userState.id
        }).then((notificationList) => {
            if (notificationList?.length === 0) {
                dispatch(executeAlert({
                    message: "Nenhuma notificação encontrada",
                    type: "warning"
                }))
            } else {
                dispatch(setNotificationsState({
                    notificationList: notificationList!
                }))
            }
        }).catch(() => {
            dispatch(executeAlert({
                message: "Erro ao buscar notificações",
                type: "error"
            }))
        })
        stopLoading()

    }, [dispatch, findCustomNotificationsApi, startLoading, stopLoading, userState.id])

    const openNotificationForm = useCallback(() => {
        dispatch(setNotificationFormOpenState({
            open: true
        }))
    }, [dispatch])

    const closeNotificationForm = useCallback(() => {
        dispatch(resetSelectedNotificationState())
        dispatch(setNotificationFormOpenState({
            open: false
        }))
    }, [dispatch])

    const setNotificationsSearchTerm = useCallback((data: {
        searchTerm: string
    }) => {
        dispatch(setNotificationsSearchTermState({
            searchTerm: data.searchTerm
        }))
    }, [dispatch])

    const selectNotification = useCallback((data?: {
        notification: IBackofficeNotification
    }) => {
        if (data) {
            dispatch(setSelectedNotificationState({
                notification: data.notification
            }))
        }

    }, [dispatch])

    const createNotification = useCallback(() => {
        dispatch(resetNotificationFormErrors())
        if (!notificationState.selectedNotification) {
            return
        }
        const errors = validateNotificationForm({
            notification: notificationState.selectedNotification
        })
        if (errors.length > 0) {
            dispatch(setNotificationFormFieldErrors(errors))
            dispatch(executeAlert({
                message: errors[0].fieldError.message,
                type: 'error'
            }))
        } else {
            createNotificationApi({
                notificationData: {
                    createdBy: userState.id,
                    img: notificationState.selectedNotification.img ?? '',
                    message: notificationState.selectedNotification.message,
                    popup: notificationState.selectedNotification.popup ?? false,
                    recipient: notificationState.selectedNotification.recipient ?? [],
                    title: notificationState.selectedNotification.title,
                    viewedBy: []
                }
            }).then((notification) => {
                dispatch(executeAlert({
                    message: 'Notificação cadastrada com sucesso',
                    type: 'success'
                }))
                setNotificationsList()
                closeNotificationForm()
            }).catch((error) => {
                dispatch(executeAlert({
                    message: error.message,
                    type: 'error'
                }))
            })
        }

    }, [closeNotificationForm, createNotificationApi, dispatch, notificationState.selectedNotification, setNotificationsList, userState.id])

    const setNotificationFileImage = useCallback((newFile: File | null) => {
        if (newFile === null) {
            dispatch(setNotificationImageFileState({
                file: undefined,
            }))
            if (notificationState.selectedNotification) {
                selectNotification({
                    notification: {
                        ...notificationState.selectedNotification,
                        img: undefined
                    }
                })
                return
            }
        }
        if (newFile!.type !== 'image/png' && newFile!.type !== 'image/jpg' && newFile!.type !== 'image/jpeg' && newFile!.type !== 'image/bmp') {
            dispatch(executeAlert({
                message: "Insira um arquivo .PNG, .JPG ou .JPEG.",
                type: "error"
            }))
            return
        }
        if (newFile!.size > (10 * 1024 * 1024)) {
            dispatch(executeAlert({
                message: "Arquivo muito grande. Insira um arquivo com no máximo 10MB.",
                type: "error"
            }))
            return
        }
        let fileReader = new FileReader()
        let isCancel = false;
        if (newFile) {
            fileReader.onload = (e: any) => {
                if (fileReader && fileReader.readyState === 1) {
                    fileReader.abort();
                    return;
                }
                const { result } = e.target;
                if (result && !isCancel) {
                    if (notificationState.selectedNotification) {
                        selectNotification({
                            ...notificationState.selectedNotification,
                            notification: {
                                ...notificationState.selectedNotification,
                                img: result
                            }
                        })
                    }
                }
                dispatch(setNotificationImageFileState({
                    file: newFile!,
                }))
            }
            fileReader.readAsDataURL(newFile);

        }

    }, [dispatch, notificationState.selectedNotification, selectNotification])

    const getRevenueReport = useCallback(() => {
        if (revenueReportState.date) {
            if (companyState.company?.master === true) {
                startLoading()
                getRevenueReportApi({
                    date: revenueReportState.date,
                    userId: userState.id
                }).then((result) => {
                    stopLoading()
                    if (result.length === 0) {
                        dispatch(executeAlert({
                            message: 'Não há registros disponíveis para este mês',
                            type: 'warning'
                        }))
                    }
                    dispatch(setRevenueReportResultState({
                        result: result
                    }))
                    stopLoading()
                }).catch((err) => {
                    stopLoading()
                    dispatch(executeAlert({
                        message: err.message,
                        type: 'error'
                    }))
                })
            } else {
                startLoading()
                getResaleRevenueReportApi({
                    date: revenueReportState.date,
                    userId: userState.id
                }).then((result) => {
                    stopLoading()
                    if (result.length === 0) {
                        dispatch(executeAlert({
                            message: 'Não há registros disponíveis para este mês',
                            type: 'warning'
                        }))
                    }
                    dispatch(setRevenueReportResultState({
                        result: result
                    }))
                }).catch((err) => {
                    stopLoading()
                    dispatch(executeAlert({
                        message: err.message,
                        type: 'error'
                    }))
                })
            }
        } else {
            dispatch(executeAlert({
                message: 'Selecione uma data',
                type: 'error'
            }))
        }
    }, [revenueReportState.date, startLoading, companyState.company?.master, getRevenueReportApi, userState.id, dispatch, stopLoading, getResaleRevenueReportApi])

    const setRevenueReportDate = useCallback((data: {
        date?: Date
    }) => {
        dispatch(setRevenueReportDateState({
            date: data.date
        }))
    }, [dispatch])

    const downloadRevenueReport = useCallback(() => {
        /* Converts the result to a XLSX file and downloads it */
        if (revenueReportState.result) {
            const mappedResult = revenueReportState.result.map((item) => {
                return [
                    item.cnpj,
                    item.razaoSocial,
                    item.tipoPlano,
                    item.status,
                    item.subscriptionConsumption,
                    item.precoUnitario,
                    item.valorAPagar
                ]
            })

            let primaryColor = '723989'
            let contrastColor = 'FFFFFFFF'
            let fgColor = 'e8e4d6'

            if (resaleConfig) {
                if (resaleConfig.id !== '') {
                    if (resaleConfig.theme.colors.primary.main !== '') {
                        primaryColor = resaleConfig.theme.colors.primary.main
                    }
                    if (resaleConfig.theme.colors.primary.contrastColor !== '') {
                        contrastColor = resaleConfig.theme.colors.primary.contrastColor
                    }
                }
            }

            xlsxGenerator(mappedResult,
                [
                    'CNPJ',
                    'Razão Social',
                    'Tipo de Plano',
                    'Status',
                    'Consumo de Assinatura',
                    'Preço unitário',
                    'Valor a pagar'
                ],
                'Relatório de Receita',
                primaryColor,
                contrastColor,
                fgColor
            )
        }
    }, [resaleConfig, revenueReportState.result])

    const setRevenueReportInitialState = useCallback(() => {
        dispatch(setRevenueReportState())
    }, [dispatch])

    const setActiveTab = useCallback((data: {
        tab: BackofficeTabs
    }) => {
        dispatch(setActiveBackofficeTab({
            tab: data.tab
        }))
    }, [dispatch])

    const findCompanyBySearchTerm = useCallback((data: {
        searchTerm: string
    }) => {
        if (data.searchTerm === '') {
            dispatch(executeAlert({
                message: 'O campo de pesquisa não deve estar em branco',
                type: 'error'
            }))
            return
        }

        const selectCompany = companyList.filter((item) => {
            // Try CNPJ
            const formattedCnpj = numericCnpj(data.searchTerm)

            if (item.company.cnpj === formattedCnpj) {
                return true
            } else {
                return false
            }
        })

        if (selectCompany.length > 0) {
            startLoading()
            findUserByCompanyIdApi({
                companyId: selectCompany[0].company.id
            }).then((users) => {
                const orderedUsers = [...users].sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    } else {
                        return 1
                    }
                })

                const userListWithConsumptionPromises: Promise<{
                    userId: string,
                    consumption: number,
                    consumptionLimit?: number
                }>[] = []

                for (let i = 0; i < orderedUsers.length; i++) {
                    const user = orderedUsers[i]
                    if (user) {
                        const result = getUserConsumptionApi({
                            userId: user.id
                        })
                        userListWithConsumptionPromises.push(result)
                    }
                }

                Promise.all(userListWithConsumptionPromises).then((result) => {
                    const userListWithConsumption: {
                        userId: string
                        consumption: number
                        consumptionLimit?: number
                    }[] = []

                    for (let i = 0; i < result.length; i++) {
                        const userConsumption = result[i]

                        if (userConsumption) {
                            userListWithConsumption.push({
                                userId: userConsumption.userId,
                                consumption: userConsumption.consumption,
                                consumptionLimit: userConsumption.consumptionLimit
                            })
                        }
                    }

                    getSubscriptionUsage({
                        companyId: selectCompany[0].company.id
                    }).then((usage) => {
                        dispatch(setSelectedCompanyState({
                            company: selectCompany[0].company,
                            separatedCredits: selectCompany[0].separatedCredits,
                            subscription: {
                                ...selectCompany[0].subscription,
                                usage
                            },
                            users: orderedUsers,
                            userListWithConsumption: userListWithConsumption
                        }))
                        stopLoading()
                    }).catch((error) => {
                        dispatch(executeAlert({
                            message: error.message,
                            type: 'error'
                        }))
                        stopLoading()
                    })

                    stopLoading()
                })

                dispatch(executeAlert({
                    message: 'Empresa selecionada com sucesso!',
                    type: 'success'
                }))
                stopLoading()
            }).catch((error) => {
                dispatch(executeAlert({
                    message: error.message,
                    type: 'error'
                }))
                dispatch(resetSelectedCompanyState())
                stopLoading()
            })
        } else {
            dispatch(executeAlert({
                message: 'Nenhuma empresa encontrada com este CNPJ / Razão Social',
                type: 'error'
            }))
            dispatch(resetSelectedCompanyState())
            stopLoading()
        }

    }, [companyList, dispatch, findUserByCompanyIdApi, getSubscriptionUsage, getUserConsumptionApi, startLoading, stopLoading])

    const setResetSelectedCompany = useCallback(() => {
        dispatch(resetSelectedCompanyState())
    }, [dispatch])

    const createBackofficeConsumptionLock = useCallback((data: {
        userId?: string
        companyId?: string
        limit: number
    }) => {
        startLoading()
        createConsumptionLockApi({
            createdBy: userState.id,
            limit: data.limit,
            companyId: data.companyId,
            userId: data.userId
        }).then(() => {
            if (selectedCompany) {
                findUserByCompanyIdApi({
                    companyId: selectedCompany.company.id
                }).then((users) => {
                    const orderedUsers = [...users].sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        } else {
                            return 1
                        }
                    })

                    const userListWithConsumptionPromises: Promise<{
                        userId: string,
                        consumption: number,
                        consumptionLimit?: number
                    }>[] = []

                    for (let i = 0; i < orderedUsers.length; i++) {
                        const user = orderedUsers[i]
                        if (user) {
                            const result = getUserConsumptionApi({
                                userId: user.id
                            })
                            userListWithConsumptionPromises.push(result)
                        }
                    }

                    Promise.all(userListWithConsumptionPromises).then((result) => {
                        const userListWithConsumption: {
                            userId: string
                            consumption: number
                            consumptionLimit?: number
                        }[] = []

                        for (let i = 0; i < result.length; i++) {
                            const userConsumption = result[i]

                            if (userConsumption) {
                                userListWithConsumption.push({
                                    userId: userConsumption.userId,
                                    consumption: userConsumption.consumption,
                                    consumptionLimit: userConsumption.consumptionLimit
                                })
                            }
                        }

                        getSubscriptionUsage({
                            companyId: selectedCompany.company.id
                        }).then((usage) => {
                            dispatch(setSelectedCompanyState({
                                company: selectedCompany.company,
                                separatedCredits: selectedCompany.separatedCredits,
                                subscription: {
                                    ...selectedCompany.subscription,
                                    usage
                                },
                                users: orderedUsers,
                                userListWithConsumption: userListWithConsumption
                            }))
                            stopLoading()
                        }).catch((error) => {
                            dispatch(executeAlert({
                                message: error.message,
                                type: 'error'
                            }))
                            stopLoading()
                        })

                        stopLoading()
                    })

                    stopLoading()
                }).catch((error) => {
                    dispatch(executeAlert({
                        message: error.message,
                        type: 'error'
                    }))
                    dispatch(resetSelectedCompanyState())
                    stopLoading()
                })
            }
            dispatch(executeAlert({
                message: 'Limitação de consumo criada com sucesso!',
                type: 'success'
            }))
            stopLoading()
        }).catch(() => {
            dispatch(executeAlert({
                message: 'Erro ao criar limitação de consumo',
                type: 'error'
            }))
            stopLoading()
        })
    }, [startLoading, createConsumptionLockApi, userState.id, selectedCompany, dispatch, stopLoading, findUserByCompanyIdApi, getUserConsumptionApi, getSubscriptionUsage])

    const resetSelectedCompanyResaleConfig = useCallback(() => {
        startLoading()
        dispatch(setResetSelectedCompanyResaleConfigState())
        stopLoading()
    }, [dispatch, startLoading, stopLoading])

    const setSelectedCompanyResaleConfigName = useCallback((data: {
        name: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigNameState({
            name: data.name
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigImg = useCallback((newFile: File | null) => {
        if (newFile === null) {
            dispatch(setSelectedCompanyResaleConfigImageFileState({
                imageFile: undefined,
            }))
            dispatch(setSelectedCompanyResaleConfigImgState({
                img: ''
            }))
            return
        }

        if (newFile!.type !== 'image/png' && newFile!.type !== 'image/jpg' && newFile!.type !== 'image/jpeg' && newFile!.type !== 'image/bmp') {
            dispatch(executeAlert({
                message: "Insira um arquivo .PNG, .JPG ou .JPEG.",
                type: "error"
            }))
            return
        }
        if (newFile!.size > (10 * 1024 * 1024)) {
            dispatch(executeAlert({
                message: "Arquivo muito grande. Insira um arquivo com no máximo 10MB.",
                type: "error"
            }))
            return
        }

        let fileReader = new FileReader()
        let isCancel = false

        if (newFile) {
            fileReader.onload = (e: any) => {
                if (fileReader && fileReader.readyState === 1) {
                    fileReader.abort()
                    return
                }
                const { result } = e.target;
                if (result && !isCancel) {
                    dispatch(setSelectedCompanyResaleConfigImgState({
                        img: result as string
                    }))
                }

                dispatch(setSelectedCompanyResaleConfigImageFileState({
                    imageFile: newFile!
                }))
            }
            fileReader.readAsDataURL(newFile);
        }

    }, [dispatch])

    const setSelectedCompanyResaleConfigFavicon = useCallback((newFile: File | null) => {
        if (newFile === null) {
            dispatch(setSelectedCompanyResaleConfigFaviconFileState({
                faviconFile: undefined
            }))
            dispatch(setSelectedCompanyResaleConfigFaviconState({
                favicon: ''
            }))

            return
        }

        if (newFile!.type !== 'image/png' && newFile!.type !== 'image/jpg' && newFile!.type !== 'image/jpeg' && newFile!.type !== 'image/bmp') {
            dispatch(executeAlert({
                message: "Insira um arquivo .PNG, .JPG ou .JPEG.",
                type: "error"
            }))
            return
        }
        if (newFile!.size > (10 * 1024 * 1024)) {
            dispatch(executeAlert({
                message: "Arquivo muito grande. Insira um arquivo com no máximo 10MB.",
                type: "error"
            }))
            return
        }

        let fileReader = new FileReader()
        let isCancel = false

        if (newFile) {
            fileReader.onload = (e: any) => {
                if (fileReader && fileReader.readyState === 1) {
                    fileReader.abort()
                    return
                }
                const { result } = e.target;
                if (result && !isCancel) {
                    dispatch(setSelectedCompanyResaleConfigFaviconState({
                        favicon: result as string
                    }))
                }

                dispatch(setSelectedCompanyResaleConfigFaviconFileState({
                    faviconFile: newFile!
                }))
            }
            fileReader.readAsDataURL(newFile);
        }
    }, [dispatch])

    const setSelectedCompanyResaleConfigDomainFrontEnd = useCallback((data: {
        domain: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigDomainFrontEndState({
            domain: data.domain
        }))
    }, [dispatch])
    const setSelectedCompanyResaleConfigDomainBackEnd = useCallback((data: {
        domain: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigDomainBackEndState({
            domain: data.domain
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigContactEmail = useCallback((data: {
        email: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigContactEmailState({
            email: data.email
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigContactPhone = useCallback((data: {
        phone: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigContactPhoneState({
            phone: data.phone
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigContactBeginHour = useCallback((data: {
        beginHour: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigContactBeginHourState({
            beginHour: data.beginHour
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigContactEndHour = useCallback((data: {
        endHour: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigContactEndHourState({
            endHour: data.endHour
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigEmailProviderSettingUser = useCallback((data: {
        user: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigEmailProviderSettingUserState({
            user: data.user
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigEmailProviderSettingPassword = useCallback((data: {
        password: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigEmailProviderSettingPasswordState({
            password: data.password
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigEmailProviderSettingHost = useCallback((data: {
        host: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigEmailProviderSettingHostState({
            host: data.host
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigEmailProviderSettingPort = useCallback((data: {
        port: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigEmailProviderSettingPortState({
            port: data.port
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigThemeColorsPrimaryMain = useCallback((data: {
        mainColor: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigThemeColorsPrimaryMainState({
            mainColor: data.mainColor
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigThemeColorsPrimaryContrastColor = useCallback((data: {
        contrastColor: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigThemeColorsPrimaryContrastColorState({
            contrastColor: data.contrastColor
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigThemeColorsSecondaryMain = useCallback((data: {
        mainColor: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigThemeColorsSecondaryMainState({
            mainColor: data.mainColor
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigThemeColorsSecondaryContrastColor = useCallback((data: {
        contrastColor: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigThemeColorsSecondaryContrastColorState({
            contrastColor: data.contrastColor
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigThemeColorsBackgroundPaperMain = useCallback((data: {
        mainColor: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigThemeColorsBackgroundPaperMainState({
            mainColor: data.mainColor
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigThemeColorsBackgroundBodyMain = useCallback((data: {
        mainColor: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigThemeColorsBackgroundBodyMainState({
            mainColor: data.mainColor
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigThemeFontFamily = useCallback((data: {
        fontFamily: string
    }) => {
        dispatch(setSelectedCompanyResaleConfigThemeFontFamilyState({
            fontFamily: data.fontFamily
        }))
    }, [dispatch])

    const setSelectedCompanyResaleConfigLoginPageImg = useCallback((newFile: File | null) => {
        if (newFile === null) {
            dispatch(setSelectedCompanyResaleConfigLoginPageImgFileState({
                loginPageImg: undefined
            }))
            dispatch(setSelectedCompanyResaleConfigLoginPageImgState({
                loginPageImg: ''
            }))
            return
        }

        if (newFile!.type !== 'image/png' && newFile!.type !== 'image/jpg' && newFile!.type !== 'image/jpeg' && newFile!.type !== 'image/bmp') {
            dispatch(executeAlert({
                message: "Insira um arquivo .PNG, .JPG ou .JPEG.",
                type: "error"
            }))
            return
        }
        if (newFile!.size > (10 * 1024 * 1024)) {
            dispatch(executeAlert({
                message: "Arquivo muito grande. Insira um arquivo com no máximo 10MB.",
                type: "error"
            }))
            return
        }

        let fileReader = new FileReader()
        let isCancel = false

        if (newFile) {
            fileReader.onload = (e: any) => {
                if (fileReader && fileReader.readyState === 1) {
                    fileReader.abort()
                    return
                }
                const { result } = e.target;
                if (result && !isCancel) {
                    dispatch(setSelectedCompanyResaleConfigLoginPageImgState({
                        loginPageImg: result as string
                    }))
                }

                dispatch(setSelectedCompanyResaleConfigLoginPageImgFileState({
                    loginPageImg: newFile!
                }))
            }
            fileReader.readAsDataURL(newFile);
        }

    }, [dispatch])

    return {
        openSubscriptionDashboard,
        closeSubscriptionDashboard,
        openCompanyForm,
        closeCompanyForm,
        setCompaniesList,
        setSearchTerm,
        addRazaoSocialFilter,
        removeRazaoSocialFilter,
        selectCompany,
        createCompany,
        updateCompany,
        feedCompanyForm,
        feedAddressFields,
        setCompanyFileImage,
        addSeparatedCredits,
        getConsumptionReport,
        resetConsumptionReport,
        setNotificationsList,
        openNotificationForm,
        closeNotificationForm,
        setNotificationsSearchTerm,
        selectNotification,
        createNotification,
        setNotificationFileImage,
        getRevenueReport,
        setRevenueReportDate,
        downloadRevenueReport,
        setRevenueReportInitialState,
        setActiveTab,
        findCompanyBySearchTerm,
        setResetSelectedCompany,
        createBackofficeConsumptionLock,
        resetSelectedCompanyResaleConfig,
        setSelectedCompanyResaleConfigName,
        setSelectedCompanyResaleConfigImg,
        setSelectedCompanyResaleConfigFavicon,
        setSelectedCompanyResaleConfigDomainFrontEnd,
        setSelectedCompanyResaleConfigDomainBackEnd,
        setSelectedCompanyResaleConfigContactEmail,
        setSelectedCompanyResaleConfigContactPhone,
        setSelectedCompanyResaleConfigContactBeginHour,
        setSelectedCompanyResaleConfigContactEndHour,
        setSelectedCompanyResaleConfigEmailProviderSettingUser,
        setSelectedCompanyResaleConfigEmailProviderSettingPassword,
        setSelectedCompanyResaleConfigEmailProviderSettingHost,
        setSelectedCompanyResaleConfigEmailProviderSettingPort,
        setSelectedCompanyResaleConfigThemeColorsPrimaryMain,
        setSelectedCompanyResaleConfigThemeColorsPrimaryContrastColor,
        setSelectedCompanyResaleConfigThemeColorsSecondaryMain,
        setSelectedCompanyResaleConfigThemeColorsSecondaryContrastColor,
        setSelectedCompanyResaleConfigThemeColorsBackgroundPaperMain,
        setSelectedCompanyResaleConfigThemeColorsBackgroundBodyMain,
        setSelectedCompanyResaleConfigThemeFontFamily,
        setSelectedCompanyResaleConfigLoginPageImg
    }
}