import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { validateCnpj, validateEmail } from '../../../../utils';
import { IBackofficeCompany, IBackofficeSubscription, IFieldError, IBackofficeCompaniesState, IBackofficeSeparatedCredits, IUser, IResaleConfig } from '../../../models';

export const validateCompanyForm = (data: {
  company: IBackofficeCompany,
  subscription: IBackofficeSubscription
}): {
  fieldError: IFieldError,
  field: string
}[] => {
  const errors: {
    fieldError: IFieldError,
    field: string
  }[] = []

  if (!data.company.razaoSocial) {
    errors.push({
      field: 'razaoSocial',
      fieldError: {
        message: 'Razão social é obrigatória',
        type: 'RequiredFieldError'
      }
    })
  }
  if (!data.company.cnpj) {
    errors.push({
      field: 'cnpj',
      fieldError: {
        message: 'CNPJ é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  if (!validateCnpj(data.company.cnpj)) {
    errors.push({
      field: 'cnpj',
      fieldError: {
        message: 'CNPJ inválido',
        type: 'InvalidCnpjError'
      }
    })
  }
  if (!data.company.email) {
    errors.push({
      field: 'email',
      fieldError: {
        message: 'E-mail é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  if (validateEmail(data.company.email)) {
    errors.push({
      field: 'email',
      fieldError: {
        message: 'E-mail inválido',
        type: 'InvalidEmailError'
      }
    })
  }
  if (!data.company.phone) {
    errors.push({
      field: 'phone',
      fieldError: {
        message: 'Telefone é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }
  if (data.company.address) {
    if (!data.company.address.state) {
      errors.push({
        field: 'address.state',
        fieldError: {
          message: 'Estado é obrigatório',
          type: 'RequiredFieldError'
        }
      })
    }
    if (!data.company.address.city) {
      errors.push({
        field: 'address.city',
        fieldError: {
          message: 'Cidade é obrigatório',
          type: 'RequiredFieldError'
        }
      })
    }
    if (!data.company.address.street) {
      errors.push({
        field: 'address.street',
        fieldError: {
          message: 'Rua é obrigatório',
          type: 'RequiredFieldError'
        }
      })
    }
    if (!data.company.address.houseNumber) {
      errors.push({
        field: 'address.houseNumber',
        fieldError: {
          message: 'Número é obrigatório',
          type: 'RequiredFieldError'
        }
      })
    }

    if (!data.company.address.postalCode) {
      errors.push({
        field: 'address.postalCode',
        fieldError: {
          message: 'CEP é obrigatório',
          type: 'RequiredFieldError'
        }
      })
    }

    if (!data.company.address.neighbourhood) {
      errors.push({
        field: 'address.neighbourhood',
        fieldError: {
          message: 'Bairro é obrigatório',
          type: 'RequiredFieldError'
        }
      })
    }
  }

  if (!data.subscription.name) {
    errors.push({
      field: 'subscription.name',
      fieldError: {
        message: 'Nome da assinatura é obrigatório',
        type: 'RequiredFieldError'
      }
    })
  }

  return errors
}

const initialState: IBackofficeCompaniesState = {
  subscriptionDashboardOpen: false,
  companyList: [],
  filters: {
    searchTerm: '',
    cnpj: [],
    email: [],
    razaoSocial: [],
    companyId: []
  },
  companyForm: {
    open: false,
    action: 'create',
    formFields: {
      address: {
        city: {
          isValid: true,
          error: undefined
        },
        houseNumber: {
          isValid: true,
          error: undefined
        },
        postalCode: {
          isValid: true,
          error: undefined
        },
        state: {
          isValid: true,
          error: undefined
        },
        street: {
          isValid: true,
          error: undefined
        },
        neighbourhood: {
          isValid: true,
          error: undefined
        }
      },
      cnpj: {
        isValid: true,
        error: undefined
      },
      email: {
        isValid: true,
        error: undefined
      },
      phone: {
        isValid: true,
        error: undefined
      },
      razaoSocial: {
        isValid: true,
        error: undefined
      },
      subscription: {
        name: {
          isValid: true,
          error: undefined
        },
        partnerCols: {
          isValid: true,
          error: undefined
        },
        stackCredits: {
          isValid: true,
          error: undefined
        },
        totalExports: {
          isValid: true,
          error: undefined
        }
      }
    }
  },
  selectedCompanyResaleConfig: {
    resaleConfig: {
      companyId: '',
      name: '',
      img: '',
      favicon: '',
      loginPageImg: '',
      domainFrontEnd: '',
      domainBackEnd: undefined,
      contact: {
        email: '',
        phone: '',
        beginHour: '',
        endHour: ''
      },
      emailProviderSettings: {
        user: '',
        password: '',
        host: '',
        port: ''
      },
      theme: {
        colors: {
          primary: {
            main: '',
            contrastColor: ''
          },
          secondary: {
            main: '',
            contrastColor: ''
          },
          background: {
            paper: {
              main: ''
            },
            body: {
              main: ''
            }
          }
        },
        fontFamily: undefined
      },
      id: '',
      createdAt: new Date(),
      updatedAt: new Date()
    }
  }
};

const backofficeCompaniesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setSubscriptionDashboardOpenState(state, action: PayloadAction<{ open: boolean }>) {
      state.subscriptionDashboardOpen = action.payload.open
    },
    setCompanyFormOpenState(state, action: PayloadAction<{ open: boolean }>) {
      state.companyForm.open = action.payload.open
    },
    setCompanyImageFileState(state, action: PayloadAction<{ file?: File }>) {
      state.companyForm.imageFile = action.payload.file
    },
    resetCompanyFormErrors(state) {
      const { formFields } = state.companyForm
      if (formFields) {
        Object.keys(formFields).forEach((key) => {
          if (key === 'address') {
            Object.keys(formFields.address).forEach((addressKey) => {
              (formFields.address as any)[addressKey].isValid = true
                ; (formFields.address as any)[addressKey].error = undefined
            })
          } else if (key === 'subscription') {
            Object.keys(formFields.subscription).forEach((subscriptionKey) => {
              (formFields.subscription as any)[subscriptionKey].isValid = true
                ; (formFields.subscription as any)[subscriptionKey].error = undefined
            })
          } else {
            (formFields as any)[key].isValid = true
              ; (formFields as any)[key].error = undefined
          }
        })
      }
    },
    resetCompanyFormState(state) {
      resetCompanyFormErrors()
      state.companyForm = initialState.companyForm
    },
    setCompanyFormFieldErrors(state, action: PayloadAction<{
      field: string,
      fieldError: IFieldError
    }[]>) {
      /* the key field represents the key of formFields object, so put this IFieldErros into de respective field */
      action.payload.forEach(fieldError => {
        const field = fieldError.field.split('.')
        if (field.length > 1) {
          (state.companyForm.formFields as any)[field[0]][field[1]].error = fieldError.fieldError;
          (state.companyForm.formFields as any)[field[0]][field[1]].isValid = false
        } else {
          (state.companyForm.formFields as any)[field[0]].error = fieldError.fieldError;
          (state.companyForm.formFields as any)[field[0]].isValid = false
        }
      })
    },
    setCompaniesState(state, action: PayloadAction<{
      companyList: {
        company: IBackofficeCompany,
        subscription: IBackofficeSubscription,
        separatedCredits: IBackofficeSeparatedCredits[],
      }[]
    }>) {
      state.companyList = action.payload.companyList
    },
    setCompaniesSearchTermState(state, action: PayloadAction<{
      searchTerm: string
    }>) {
      state.filters.searchTerm = action.payload.searchTerm
    },
    addRazaoSocialFilterState(state, action: PayloadAction<{
      razaoSocial: string,
      companyId: string
    }>) {
      const { razaoSocial, companyId } = action.payload
      const { filters } = state
      if (filters) {
        if (filters.razaoSocial) {
          const findRazaoSocial = filters.razaoSocial.find(company => company === razaoSocial)
          if (!findRazaoSocial) {
            filters.razaoSocial.push(razaoSocial)
            filters.companyId?.push(companyId)
          }
        } else {
          filters.razaoSocial = []
          filters.razaoSocial.push(razaoSocial)
          filters.companyId = []
          filters.companyId.push(companyId)
        }
      }
    },
    removeRazaoSocialFilterState(state, action: PayloadAction<{
      razaoSocial: string,
      companyId: string
    }>) {
      const { razaoSocial, companyId } = action.payload
      const { filters } = state
      if (filters) {
        if (filters.razaoSocial) {
          const findRazaoSocial = filters.razaoSocial.find(company => company === razaoSocial)
          const findCompanyId = filters.companyId!.find(id => id === companyId)
          if (findRazaoSocial) {
            const index = filters.razaoSocial.indexOf(findRazaoSocial)
            filters.razaoSocial.splice(index, 1)
            const indexCompanyId = filters.companyId!.indexOf(findCompanyId!)
            filters.companyId!.splice(indexCompanyId, 1)
          }
        }
      }
    },
    resetSelectedCompanyState(state) {
      state.selectedCompany = undefined
    },
    setSelectedCompanyState(state, action: PayloadAction<{
      company: IBackofficeCompany,
      users?: IUser[]
      userListWithConsumption?: {
        userId: string
        consumption: number
        consumptionLimit?: number
      }[]
      subscription: IBackofficeSubscription,
      separatedCredits: IBackofficeSeparatedCredits[]
    }>) {
      const { company, subscription, separatedCredits, users, userListWithConsumption } = action.payload
      state.selectedCompany = {
        company,
        users,
        consumptions: {
          userListWithConsumption: userListWithConsumption ?? []
        },
        subscription,
        separatedCredits
      }
    },
    setResetSelectedCompanyResaleConfigState(state) {
      state.selectedCompanyResaleConfig = initialState.selectedCompanyResaleConfig
    },
    setSelectedCompanyResaleConfigState(state, action: PayloadAction<{
      resaleConfig: IResaleConfig
    }>) {
      state.selectedCompanyResaleConfig.resaleConfig = action.payload.resaleConfig
    },
    setSelectedCompanyResaleConfigNameState(state, action: PayloadAction<{
      name: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.name = action.payload.name
      }
    },
    setSelectedCompanyResaleConfigImgState(state, action: PayloadAction<{
      img: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.img = action.payload.img
      }
    },
    setSelectedCompanyResaleConfigImageFileState(state, action: PayloadAction<{
      imageFile?: File
    }>) {
      state.selectedCompanyResaleConfig.headerImageFile = action.payload.imageFile
    },
    setSelectedCompanyResaleConfigFaviconState(state, action: PayloadAction<{
      favicon: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.favicon = action.payload.favicon
      }
    },
    setSelectedCompanyResaleConfigFaviconFileState(state, action: PayloadAction<{
      faviconFile?: File
    }>) {
      state.selectedCompanyResaleConfig.faviconFile = action.payload.faviconFile
    },
    setSelectedCompanyResaleConfigLoginPageImgState(state, action: PayloadAction<{
      loginPageImg: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.loginPageImg = action.payload.loginPageImg
      }
    },
    setSelectedCompanyResaleConfigLoginPageImgFileState(state, action: PayloadAction<{
      loginPageImg?: File
    }>) {
      state.selectedCompanyResaleConfig.loginPageImg = action.payload.loginPageImg
    },
    setSelectedCompanyResaleConfigDomainFrontEndState(state, action: PayloadAction<{
      domain: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.domainFrontEnd = action.payload.domain
      }
    },
    setSelectedCompanyResaleConfigDomainBackEndState(state, action: PayloadAction<{
      domain: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.domainBackEnd = action.payload.domain
      }
    },
    setSelectedCompanyResaleConfigContactEmailState(state, action: PayloadAction<{
      email: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.contact.email = action.payload.email
      }
    },
    setSelectedCompanyResaleConfigContactPhoneState(state, action: PayloadAction<{
      phone: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.contact.phone = action.payload.phone
      }
    },
    setSelectedCompanyResaleConfigContactBeginHourState(state, action: PayloadAction<{
      beginHour: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.contact.beginHour = action.payload.beginHour
      }
    },
    setSelectedCompanyResaleConfigContactEndHourState(state, action: PayloadAction<{
      endHour: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.contact.endHour = action.payload.endHour
      }
    },
    setSelectedCompanyResaleConfigEmailProviderSettingUserState(state, action: PayloadAction<{
      user: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.emailProviderSettings.user = action.payload.user
      }
    },
    setSelectedCompanyResaleConfigEmailProviderSettingPasswordState(state, action: PayloadAction<{
      password: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.emailProviderSettings.password = action.payload.password
      }
    },
    setSelectedCompanyResaleConfigEmailProviderSettingHostState(state, action: PayloadAction<{
      host: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.emailProviderSettings.host = action.payload.host
      }
    },
    setSelectedCompanyResaleConfigEmailProviderSettingPortState(state, action: PayloadAction<{
      port: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.emailProviderSettings.port = action.payload.port
      }
    },
    setSelectedCompanyResaleConfigThemeColorsPrimaryMainState(state, action: PayloadAction<{
      mainColor: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.theme.colors.primary.main = action.payload.mainColor
      }
    },
    setSelectedCompanyResaleConfigThemeColorsPrimaryContrastColorState(state, action: PayloadAction<{
      contrastColor: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.theme.colors.primary.contrastColor = action.payload.contrastColor
      }
    },
    setSelectedCompanyResaleConfigThemeColorsSecondaryMainState(state, action: PayloadAction<{
      mainColor: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.theme.colors.secondary.main = action.payload.mainColor
      }
    },
    setSelectedCompanyResaleConfigThemeColorsSecondaryContrastColorState(state, action: PayloadAction<{
      contrastColor: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.theme.colors.secondary.contrastColor = action.payload.contrastColor
      }
    },
    setSelectedCompanyResaleConfigThemeColorsBackgroundPaperMainState(state, action: PayloadAction<{
      mainColor: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.theme.colors.background.paper.main = action.payload.mainColor
      }
    },
    setSelectedCompanyResaleConfigThemeColorsBackgroundBodyMainState(state, action: PayloadAction<{
      mainColor: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.theme.colors.background.body.main = action.payload.mainColor
      }
    },
    setSelectedCompanyResaleConfigThemeFontFamilyState(state, action: PayloadAction<{
      fontFamily: string
    }>) {
      if (state.selectedCompanyResaleConfig.resaleConfig) {
        state.selectedCompanyResaleConfig.resaleConfig.theme.fontFamily = action.payload.fontFamily
      }
    }
  }
});

export const {
  setCompaniesState,
  setCompaniesSearchTermState,
  addRazaoSocialFilterState,
  removeRazaoSocialFilterState,
  setSelectedCompanyState,
  setCompanyFormFieldErrors,
  resetCompanyFormErrors,
  setCompanyImageFileState,
  setCompanyFormOpenState,
  resetCompanyFormState,
  resetSelectedCompanyState,
  setSubscriptionDashboardOpenState,
  setSelectedCompanyResaleConfigContactBeginHourState,
  setSelectedCompanyResaleConfigContactEmailState,
  setSelectedCompanyResaleConfigContactEndHourState,
  setSelectedCompanyResaleConfigContactPhoneState,
  setSelectedCompanyResaleConfigDomainFrontEndState,
  setSelectedCompanyResaleConfigDomainBackEndState,
  setSelectedCompanyResaleConfigEmailProviderSettingHostState,
  setSelectedCompanyResaleConfigEmailProviderSettingPasswordState,
  setSelectedCompanyResaleConfigEmailProviderSettingPortState,
  setSelectedCompanyResaleConfigEmailProviderSettingUserState,
  setSelectedCompanyResaleConfigFaviconFileState,
  setSelectedCompanyResaleConfigFaviconState,
  setSelectedCompanyResaleConfigImageFileState,
  setSelectedCompanyResaleConfigImgState,
  setSelectedCompanyResaleConfigLoginPageImgFileState,
  setSelectedCompanyResaleConfigLoginPageImgState,
  setSelectedCompanyResaleConfigNameState,
  setSelectedCompanyResaleConfigState,
  setSelectedCompanyResaleConfigThemeColorsBackgroundBodyMainState,
  setSelectedCompanyResaleConfigThemeColorsBackgroundPaperMainState,
  setSelectedCompanyResaleConfigThemeColorsPrimaryContrastColorState,
  setSelectedCompanyResaleConfigThemeColorsPrimaryMainState,
  setSelectedCompanyResaleConfigThemeColorsSecondaryContrastColorState,
  setSelectedCompanyResaleConfigThemeColorsSecondaryMainState,
  setSelectedCompanyResaleConfigThemeFontFamilyState,
  setResetSelectedCompanyResaleConfigState
} = backofficeCompaniesSlice.actions;

export const backofficeCompaniesReducer = backofficeCompaniesSlice.reducer;